import React , {Component} from 'react';
import Navbar from './navbar';


class Header extends Component{

	


	render(){
		return(
			<header>
			<div className="container-fluid">
			<div className="row">
			<Navbar>
			</Navbar>
			</div>
			</div>
			</header>

			)
	}

}
export default Header;