import React, { Component } from 'react';
import cover from './image/cover.jpg';
import './App.css';
import Header from './components/header';
import RecentPost from './components/recentPost';
import PopularPost from './components/popularPost';
import axios from 'axios';
import {  Link } from 'react-router-dom'
import 'font-awesome/css/font-awesome.min.css';
const imageUrl = "https://subhakamana.com/samavedana/public/uploads/";


class App extends Component {
  constructor(props){
    super(props);
    this.state={

      events:[],
      value:'',
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

   handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    
    event.preventDefault();
    axios.post('/template/search',{search:this.state.value})
    .then(response =>{
      console.log('success');
    })

  }


  componentDidMount(){
   axios.get('/template/getUpcomingEvents')
   .then(response=>{
    return response.data
  })
   .then(events=> this.setState({events:events}))

 }


 render() {
  return (
    <>
   <Header>
   </Header>
<section className="cover-image">
  <div className="container-fluid">
    <div className="row">
      <div className="top-image w-100">
        <img src={cover} alt="cover"/>
        <figcaption>
        <h1 className="text-bold text-light mb-md-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>

            <form onSubmit={this.handleSubmit}>
              <div className="input-group h2 mt-3">
                <input type="text" className="form-control search text-center px-md-5" value ={this.state.submit} onChange={this.handleChange} placeholder="Search 1000+ templates" aria-label="search" aria-describedby="search"/>
                
                <div className="input-group-append pl-0">
                <Link to={{pathname:`search/${this.state.value}`}}>  <button className="btn btn-primary search-button px-md-5" type="submit" id="search"><i className="fa fa-search"></i><span className="d-none d-md-inline">&nbsp;&nbsp;Search</span></button></Link>
                </div>
              </div>
            </form>
        </figcaption>
      </div>
    </div>
  </div>
</section>

    <section className="related-block">
    <div className="container">
    <div className="row mb-5">
    {this.state.events.map((image,index) =>{
      return(

       <div className="single-temp col-6 col-md-3 mb-3 mb-md-0" key={index}>
       <div className="inner-temp rounded">
       <Link to={{pathname:`view/${image.slug}`}}>
       <img className="rounded" src={imageUrl+image.sample_image} alt={image.caption}/>
       <div className="overlay">
       <p className="text">{image.caption}</p>
       </div>
       </Link>
       </div>


       </div>

       );

    })
  }

  </div>
  </div>

  </section>
  <RecentPost/>
  <PopularPost/>
  </>
  );
}
}

export default App;
