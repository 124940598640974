import React , {Component} from 'react';
import axios from 'axios';
import Slider from './slider';

class PopularPost  extends Component{

	constructor(props){
		super(props);
		this.state = {
			posts:[],
			popularPosts:[]
		}
	}


	componentDidMount(){
		axios.get('/template/getPopularPost')
		.then(response=>{
			
			return response.data
		})
		.then(posts=> this.setState({popularPosts:posts}));
		
		// .then(images=> this.setState({images:images}))
	}

	render(){

		return(
			

			<section className="popular-block">
			<div className="image-slider container-fluid ">
			<div className="row py-5">
			<h1 className="text-center w-100">Popular Posts</h1>
			<p className="text-style text-center w-100">Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit</p>
			<Slider list={this.state.popularPosts} showCount={5} responsiveWidth={700} responsiveShowCount={2} gap={20} row={2}/>
			</div>
			</div>
			
			</section>

			)
	}

}
export default PopularPost;