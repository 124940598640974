import React, {Component} from 'react';
import Slide from './slide';
import LeftArrow from './leftarrow';
import RightArrow from './rightarrow';
const imageUrl = "http://upvedatech.com/samavedana/public/uploads/";


class Slider extends Component{
	constructor(props){
		super(props);
		 let length =props.list.length;
		 let slideWidth = 100/props.showCount; 
		 let wrapperWidth = slideWidth * length;
		this.state={
			images:[],
			currentIndex :0,
			translateValue:0,
			list:[],
			length:0,
			slideWidth:0,
			row:props.row||1,
			wrapperWidth,
			showCount:window.innerWidth>props.responsiveWidth?props.showCount:props.responsiveShowCount
		};
		this.resize = this.resize.bind(this);
		window.addEventListener('resize',this.resize);
	}

	resize(e){
		 let wrapperWidth = 100/this.state.showCount*this.state.length; 
			this.setState({
			showCount:window.innerWidth>this.props.responsiveWidth?this.props.showCount:this.props.responsiveShowCount,
			wrapperWidth
		})}

	goToPrevSlide = () =>{
		this.setState(prevState =>({
			currentIndex:prevState.currentIndex===0?-(((this.state.length/this.state.row)-this.state.showCount)<1?0:((this.state.length/this.state.row)-this.state.showCount)):(prevState.currentIndex+1),
		})

		);



	}

	goToNextSlide = ()=>{
		this.setState(prevState => ({
			currentIndex:(prevState.currentIndex===-((this.state.length/this.state.row)-this.state.showCount)?0:(prevState.currentIndex-1))
		}));

	}
	slideWidth=()=>{
		console.log(document.querySelector('.slide').clientWidth);
		return document.querySelector('.slide').offsetWidth
	}

	componentDidMount(){
		this.resize();
	}
	async componentWillReceiveProps(newProps) {    

		 let length =newProps.list.length;
		 let slideWidth = 100/newProps.showCount; 
		 let wrapperWidth = slideWidth * length;
		await this.setState({
			list:newProps.list,
			length,
			slideWidth,
			wrapperWidth,
			showCount:window.innerWidth>newProps.responsiveWidth?newProps.showCount:newProps.responsiveShowCount
		});
		this.resize();
   }
   componentWillUnmount(){
   	window.removeEventListener('resize',this.resize);
   }

	render(){
		return(
			<div className='slider' style={{width:'100%',position:'relative'}}  key={`width${this.state.wrapperWidth}`}>
			
			<div className='slider-wrapper-outer' style={{width:'100%',overflow:'hidden'}}>
			<div className ='slider-wrapper'
				style = {{
					transform: `translateX(${((this.state.currentIndex)*100/this.state.length)*this.state.row}%)`,
					transition: `transform ease-out 0.45s`,
					display:`flex`,
					width:(this.state.wrapperWidth/this.state.row)+'%',
					alignItems:'stretch',
					flexWrap:'wrap'
				}}
				>
			{
				this.state.list.map((image,i) =><Slide key ={i} image ={imageUrl+image.sample_image} width={100/this.state.length*this.state.row} gap={this.props.gap} height={this.props.height}/>)

			}
			</div>
			</div>
			<LeftArrow
			goToPrevSlide = {this.goToPrevSlide}
			style = {style.arrow}
			/>

			<RightArrow
			goToNextSlide = {this.goToNextSlide}
			style = {{...style.arrow,...style.rightArrow}}
			/>

			
			</div>

			
			);
	}

}

export default  Slider;
const  style = {
	arrow:{
		position:'absolute',
		top:'50%',
		transform:'translateY(-50%)',
		padding:20,
		backgroundColor:'rgba(0,0,0,.5)',
		color:'#fff'
	},
	rightArrow:{
		right:0
	}
}