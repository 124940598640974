import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Search from './search';
import View from './view';
import App from "../App";

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/"  component={App} exact />
      {<Route exact path="/search/:id" component={Search} />}
      {<Route exact path="/view/:slug" component={View} />}
    </Switch>
  </BrowserRouter>
);

export default Router;



