import React, { Component } from 'react';
import Header from './header';
import cover from '../image/cover1.jpg';
import axios from 'axios';
import '../App.css';
const imageUrl = "http://upvedatech.com/samavedana/public/uploads/";



class Search extends Component{

	constructor(props){
		super(props);
		this.state = {
			search:props.match.params.id,
			results:[],
			// value:'',

		}
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getPopular = this.getPopular.bind(this);
		this.getRecent = this.getRecent.bind(this);
		
	}
	
	handleChange(event) {
		this.setState({search: event.target.value});
	}

	handleSubmit(event) {

		event.preventDefault();
		axios.post('/template/search',{search:this.state.search})
		.then(response =>{
			return response.data
		}).then(results =>this.setState({results:results}))

	}
	getPopular(event){
		axios.post('/template/search',{search:this.state.search,type:'popular'})
		.then(response =>{
			return response.data
		}).then (results =>this.setState({results:results}))
	}
	getRecent(event){
		axios.post('/template/search',{search:this.state.search,type:'recent'})
		.then(response =>{
			return response.data
		}).then (results =>this.setState({results:results}))
	}


	componentDidMount(){

		axios.post('/template/search',{search:this.state.search})
		.then(response =>{
			return response.data
		})
		.then(results=> this.setState({results:results}))
	
}

render(){
	return(
		<React.Fragment>
		<Header>
		</Header>
	<section className="cover-image1">
		<div className="container-fluid">
			<div className="row">
				<div className="top-image w-100">
					<img src={cover} alt="cover"/>
					<figcaption>
					<h1 className="text-bold text-light mb-md-5">Couldn't find what you are searching for?</h1>
					<form onSubmit={this.handleSubmit}>
						<div className="input-group h2 mt-3">
							<input type="text" className="form-control search text-center px-md-5" value ={this.state.submit} onChange={this.handleChange} placeholder="Search 1000+ templates" aria-label="search" aria-describedby="search"/>
							<div className="input-group-append pl-0">
								<button className="btn btn-primary search-button px-md-5" type="submit" id="search"><i className="fa fa-search"></i><span className="d-none d-md-inline">&nbsp;&nbsp;Search</span></button>
								
							</div>
						</div>
					</form>
					</figcaption>
				</div>
			</div>
		</div>
	</section>
	<section className="result-block">
		<div className="className">
			<div className="row className-5 pt-4 pb-2 border-bottom">
				<div className="col-12 col-md-6" >
					<p>Search results for <b>{this.state.search}</b></p>
				</div>
				<div className="col-12 col-md-6">
					<ul className="list d-flex justify-content-end mb-md-0  pl-0">
						<li className="mr-2 active"><a href="#" className="p-2 p-md-3">All</a></li>
						<li className="mr-2"><a href="#" onClick={this.getPopular} className="p-2 p-md-3">Popular</a></li>
						<li className="mr-2"><a href="#" onClick={this.getRecent} className="p-2 p-md-3">Recent</a></li>
					</ul>
				</div>
			</div>
		</div>
	</section>
	<section className="cards-block">
		<div className="container">
			<div className="row mb-5">
				{this.state.results.map((image,index) =>{
				return(
				<div className="single-temp col-6 col-md-4 col-lg-3 mb-3 mb-md-0 py-3" key={image.caption}>
					<div className="inner-temp rounded">
						<img className="rounded" src={imageUrl+image.sample_image} alt={image.caption}/>
						<div className="overlay">
							<p className="text">{image.caption}</p>
						</div>
					</div>
				</div>
				);
				})
				}
			</div>
		</div>
	</section>
	</React.Fragment>

	)
}
}

export default Search;