import React , {Component} from 'react';
import logo from '../image/logo.png';
import axios from 'axios';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';

  class Navigation extends Component{

   constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state ={
     isOpen:false,
     menus:{0:[]},
   };
 }
 toggle(){
  this.setState({
   isOpen :!this.state.isOpen
 });
}

componentDidMount(){
  axios.get('/menu/menu')
  .then(response=>{
    return response.data
  })
  .then(menus => {
    let sortedMenu = {};
    for(let menu in menus){
     let pid = menus[menu].parent_id; 
     sortedMenu[pid] = sortedMenu[pid] || [];
     sortedMenu[pid].push(menus[menu]);
   }
   this.setState({menus:sortedMenu});

 });
}



    // ));

// }


render(){
  return(

    <Navbar expand="lg" className="navbar-slide w-100 py-0">
    <NavbarBrand href="/" className="py-0"><img src={logo} alt="logo"/></NavbarBrand>
    <NavbarToggler onClick={this.toggle} />
    <Collapse isOpen={this.state.isOpen} navbar>
    <Nav className="ml-auto" navbar>

    {
     this.state.menus[0].map((menu,index) =>{
       if(!this.state.menus[menu.id]){
        return(
          <NavItem key={`navItem-${index}`}>
          <NavLink href="/components/">{menu.title}</NavLink>
          </NavItem>
          )
      }else{
        return(
          <UncontrolledDropdown nav inNavbar key={`navItem-${index}`}>
          <DropdownToggle nav caret>
          {menu.title}
          </DropdownToggle>
          <DropdownMenu right>
          {
            this.state.menus[menu.id].map(subMenu=>
              <DropdownItem href={'/category/'+subMenu.slug} key={`navitem-${subMenu.id}`}>
              {subMenu.title}
              </DropdownItem>
              )
          }
          </DropdownMenu>       
          </UncontrolledDropdown>
          )
      }

    }
      // if(!menu.parent_id){
      //   return(
      //     <NavItem key={index}>
      //     <NavLink href="/components/">{menu.title}</NavLink>
      //     </NavItem>
      //     )

      // }else{
      //   return( <UncontrolledDropdown nav inNavbar>
      //    <DropdownToggle nav caret>
      //    {menu.title}
      //    </DropdownToggle>
      //    <DropdownMenu right>
      //    <DropdownItem href="/components">
      //    {menu.title}
      //    </DropdownItem>
      //    </DropdownMenu>
      //    </UncontrolledDropdown>

      //    )
      // }
      )

   }  
   </Nav>
   </Collapse>
   </Navbar>
   )
}
}

export default Navigation;