import React, { Component } from 'react';
import Header from './header';
import template from '../image/template.png';
import edit from '../image/edit.png';
import share from '../image/share.png';
import axios from 'axios';
import '../App.css';
import 'font-awesome/css/font-awesome.min.css';

import { Nav, NavLink, NavItem, TabContent, TabPane, Row } from 'reactstrap';
import classnames from 'classnames';
import FacebookLoginWithButton from 'react-facebook-login';
import { FacebookProvider, ShareButton } from 'react-facebook';
import {Helmet} from "react-helmet";

const imageUrl = "https://subhakamana.com/samavedana/public/uploads/";
// const base_url = window.location.href;
// const myOnLoadHandler =[] ;


class View extends Component {

	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			activeTab: '1',
			search: props.match.params.id,
			new_search: props.match.params.slug,
			results: [],
			svg: [],
			name: '',
			date: '',
			message: '',
			image: '',
			new_image: '',
			convertedImage: [],
			new_svg: '',
		}
		this.handleSvg = this.handleSvg.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.changeSvg = this.changeSvg.bind(this);
		this.changeImage = this.changeImage.bind(this);
	}


	handleChange(event) {
		this.setState({ search: event.target.value });
	}

	handleSvg(event) {
		event.preventDefault();
		let svg_image = document.getElementsByTagName('svg')[0];
		var foreign = document.createElementNS('http://www.w3.org/2000/svg', "foreignObject");
		foreign.setAttribute('width', 500);
		foreign.setAttribute('height', 500);
		var iDiv = document.createElement('div');
		iDiv.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');
		var t = document.createTextNode("This is a paragraph.");

		iDiv.appendChild(t);
		foreign.appendChild(iDiv);
		svg_image.appendChild(foreign);

		this.setState({ new_svg: svg_image });
		axios.post('/template/convertImage', { image: svg_image.outerHTML })
			.then(response => {
				return response.data
			}).then(results => this.setState({ convertedImage: results }))

	}
	changeSvg(event) {
		this.setState({ message: event.target.value });
		event.preventDefault();
		document.getElementById('message').textContent = this.state.message;
	}
	changeImage(event) {
		event.preventDefault();
		let reader = new FileReader();
		let file = event.target.files[0];
		let new_image = '';
		reader.onloadend = () => {
			this.setState({
				file: file,
				new_image: reader.result,
			});
			document.getElementById('image').href.baseVal = reader.result;
		}
		reader.readAsDataURL(file);
		console.log(document.getElementById('image').href)
	}

	handleSubmit(event) {

		event.preventDefault();
		axios.post('/template/search', { search: this.state.search })
			.then(response => {
				return response.data
			}).then(results => this.setState({ results: results }))

	}
	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	responseFacebook = (response) => {
		console.log(response);
	}

	componentClicked = () => {
		console.log("Clicked!")
	}


	componentDidMount() {
		axios.get('https://subhakamana.com/samavedana/public/uploads/svg_images\/template.svg').then(function (res) {
			console.log(res);
		});
		axios.post('/template/related_search', { search: this.state.search })
			.then(response => {
				return response.data
			})
			.then(results => this.setState({ results: results }));

		axios.post('/template/get_svg', { search: this.state.new_search })
			.then(response => {
				return response.data
			})
			.then(svg_image => {
				this.setState({ svg: svg_image });
				let test_svg = document.getElementsByTagName('svg')[0];
				// console.log(test_svg.querySelector('#message').getBBox());
			});
	}

	render() {
		return (
			<React.Fragment>
				<Header>
				</Header>
				<Helmet>
                <meta charSet="utf-8" />
                <title>Shuvakama </title>
				<meta name="description" content={this.state.new_search} />
                <link rel="canonical" href="https://shuvakamana.com" />
            </Helmet>
				<section>
					<div className="container-fluid">
						<div className="row">

							<div className="left-tab flex-row col-12 col-sm-5 col-lg-3 p-0">
								<Nav tabs className="nav-tabs--vertical nav-tabs--left">
									<NavItem>
										Template
			<NavLink className={'text-center p-3 ' + classnames({ active: this.state.activeTab === '1' })}
											onClick={() => { this.toggle('1'); }}>
											<img src={template} alt="template" />
										</NavLink>
									</NavItem>

									<NavItem>
										Edit
			<NavLink className={'text-center p-3 ' + classnames({ active: this.state.activeTab === '2' })}
											onClick={() => { this.toggle('2'); }}>
											<img src={edit} alt="edit" />
										</NavLink>
									</NavItem>

									<NavItem>
										Share
												<NavLink className={'text-center p-3 ' + classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('3'); }}>
											<img src={share} alt="share" />
										</NavLink>
									</NavItem>
								</Nav>

								<TabContent activeTab={this.state.activeTab} className="edit-content">
									<TabPane tabId="1" >
										<form onSubmit={this.handleSubmit}>

											<div className="input-group mb-3">
												<input type="text" className="form-control" value={this.state.submit} onChange={this.handleChange} placeholder="Search" aria-label="search" aria-describedby="search" />
												<div className="input-group-append">
													<button className="btn btn-primary px-2 py-1" type="button" id="search"><i className="fa fa-search"></i></button>
												</div>
											</div>
										</form>
										<Row>


											{this.state.results.map((image, index) => {
												return (
													<div className="single-temp col-6 mb-3" key={image.title}>
														<div className="inner-temp rounded shadow-none">

															<a href="#"><img src={imageUrl + image.sample_image} className="small-img" alt={image.title} /></a>
														</div>
													</div>
												);
											})
											}

										</Row>

									</TabPane>

									<TabPane tabId="2">
										<form onSubmit={this.handleSvg}>
											<div className="row justify-content-center">
												<div className="col-12">
													<input type="text" className="form-control form-control-sm pl-3 mb-2" value={this.state.value} onChange={this.changeSvg} placeholder="Name" />
												</div>

												<div className="form-group col-12">
													<textarea className="form-control form-control-sm pl-3" rows="5" onChange={this.changeSvg} placeholder="Message"></textarea>
												</div>

												<input type='file' onChange={this.changeImage} />

												<button type="submit" className="btn btn-primary font-min px-3"><span><i className="fa fa-check mr-2"> </i></span> Done</button>
											</div>
										</form>

									</TabPane>
									<TabPane tabId="3">
										<h3 className="text-light mb-3">Share your Design</h3>
										<ul className="pl-0">
											<li>
												{/* <button className="btn btn-secondary rounded-20 fb mb-2 w-100"><a href="#"><i className="fa fa-facebook"></i></a></button> */}
												<FacebookLoginWithButton
													appId="2056809237775306"
													autoLoad
													fields="name,email,picture"
													// version="3.1"
													// onClick={componentClicked}
													callback={this.responseFacebook}
													icon="fa-facebook" />
											</li>
											<li><button className="btn btn-secondary rounded-20 twit mb-2 w-100">
												<a href="#"><i className="fa fa-twitter"></i></a></button>
											</li>
											<li><button className="btn btn-secondary rounded-20 gplus mb-2 w-100">
												<a href="#"><i className="fa fa-google-plus"></i></a></button>
											</li>
										</ul>
									</TabPane>
								</TabContent>
							</div>

							<div className="artboard col-12 col-sm-7 col-lg-9">
								<div className="container">
									<div className="row">
										<div className="work-desk shadow" dangerouslySetInnerHTML={{ __html: this.state.svg }}>
										</div>


										<button className="floatingbutton"><i className="fas fa-th-list"></i></button>
									</div>
								</div>
							</div>
							<FacebookProvider appId="2056809237775306">
								<ShareButton href="https://reactjs.org/docs/getting-started.html">
									Share
        </ShareButton>
							</FacebookProvider>


						</div>

					</div>
				</section>
			</React.Fragment>
		)
	}
}

export default View;